import React, { useEffect, useState, useCallback } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from './../types';
import './SimplePanel.css';
import { Button, Input, Card } from '@grafana/ui';


interface Props extends PanelProps<SimpleOptions> {}

const removeAnsiCodes = (str: any) => str.replace(/\u001b\[[0-9;]*m/g, "");

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [chatHistory, setChatHistory] = useState<Array<{author: string, message: string}>>([]);
  const [userQuery, setUserQuery] = useState<string>('');

  // Create a memoized version of replaceVariables
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedReplaceVariables = useCallback(replaceVariables, []);

  useEffect(() => {
    const socket = new WebSocket(options.websocket_url);

    socket.onopen = () => {
      console.log('Connected to WebSocket server');
      setWs(socket);
      // Initialize the model
      socket.send(JSON.stringify({
        topic: 'init',
        payload: {
          connection_string: options.connection_string,
          sql: memoizedReplaceVariables(options.sql),
          llm_code_model_name: options.llm_code_model_name,
          llm_context_model_name: options.llm_context_model_name,
          api_key: options.api_key
        }
      }));
    };

    socket.onmessage = (event) => {
      console.log(`Received message: ${event.data}`);
      let messageContent = "";
      
      try {
        const parsedMessage = JSON.parse(event.data);
        
        if (parsedMessage && typeof parsedMessage === "object") {
          // Extract fields from the message
          const { observation, thoughts } = parsedMessage;
    
          // Remove ansi codes from the strings
          const cleanedObservation = removeAnsiCodes(observation);
          const cleanedThoughts = thoughts.map(removeAnsiCodes);
    
          // Format the observation as a HTML table
          const table = cleanedObservation.split('\n').map((row: string) => `<tr><td>${row.split('\t').join('</td><td>')}</td></tr>`).join('');
    
          // Combine the observation and thoughts into a single string
          // Combine the observation and thoughts into a single string
          messageContent = `<strong>Thought:</strong><br/>${cleanedThoughts[0]}<br/><br/><strong>Observation:</strong><br/><table>${table}</table><br/><strong>Final Answer:</strong><br/>${cleanedThoughts[1]}`;

          //messageContent = `<strong>Thought:</strong> ${cleanedThoughts[0]}<br/><strong>Observation:</strong><br/><table>${table}</table><br/><strong>Final Answer:</strong> ${cleanedThoughts[1]}`;

        }
      } catch (e) {
        console.error(`Failed to parse message as JSON: ${e}`);
        // If parsing fails, use the raw message
        messageContent = event.data;
      }
    
      setChatHistory(prevChat => [...prevChat, { author: 'Model', message: messageContent }]);
    };    

    socket.onerror = (error) => {
      console.error(`WebSocket error: ${error}`);
    };

    socket.onclose = (event) => {
      console.log('WebSocket connection closed');
    };

    return () => {
      socket.close();
    };
  }, [options.websocket_url, options.connection_string, options.sql, options.llm_code_model_name, options.llm_context_model_name, options.api_key, memoizedReplaceVariables]);

  const queryModel = () => {
    if (ws) {
      ws.send(JSON.stringify({
        topic: 'question',
        payload: { question: userQuery }
      }));
      setChatHistory(prevChat => [...prevChat, { author: 'User', message: userQuery }]);
      setUserQuery('');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserQuery(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      queryModel();
    }
  };

  return (
    <div
      className="wrapper"
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <div className="chatHistory">
      {chatHistory.map((chat, index) => (
        <Card
          key={index}
          heading={chat.author}
          //ariaLabel={chat.author}
          className={`chat-card chat-${chat.author}`}
        >
          <p className="chat-message" dangerouslySetInnerHTML={{ __html: chat.message }} />
        </Card>
      ))}
      </div>
      <div className="controls">
      <Input
        className="input"
        type="text"
        value={userQuery}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Ask a question..."
      />
      <Button
        className="button"
        onClick={queryModel}
        disabled={!userQuery.trim()} // Button will be disabled if userQuery is empty or whitespace
      >Send</Button>
      </div>
    </div>
  );  
};
