import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './components/SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder
  .addTextInput({
    path: 'sql',
    name: 'Select query',
    description: 'sql query for GPT to work with',
  })
  .addTextInput({
    path: 'llm_code_model_name',
    name: 'llm_code_model_name',
    description: 'gpt-3.5-turbo-16k-0613',
  })
  .addTextInput({
    path: 'llm_context_model_name',
    name: 'llm_context_model_name',
    description: 'gpt-3.5-turbo',
  })
  .addTextInput({
    path: 'api_key',
    name: 'api_key',
    description: 'api key for chat GPT',
    defaultValue: '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',
  })
  .addTextInput({
    path: 'websocket_url',
    name: 'websocket url',
    description: 'ws://kamery.edima.io:10012',
  })
  .addTextInput({
    path: 'connection_string',
    name: 'connection_string',
    description: 'postgresql://postgres:rgEhqCzJ45WIHD0@timescaleDB:5432/postgres',
  })
    ;
});
